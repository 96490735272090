import React from "react"
import { Link } from "gatsby"

import embeddedAccount from '../assets/admin-embedded-account.png';
import embeddedApp from '../assets/admin-embedded-app.png';
import embeddedInitial from '../assets/create-app-full2.png';
import embeddedStarted from '../assets/admin-embedded-started.png';
import adminHeader from '../assets/admin-header.png';
import adminProjects from '../assets/admin-projects.png';
import redesigned1 from '../assets/admin-redesigned1.png';
import redesigned2 from '../assets/admin-redesigned2.png';
import redesigned3 from '../assets/admin-redesigned3.png';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img src={adminHeader} alt="Mariana Tek Admin UI Kit" />
            <div className="align-left margin-top-neg margin-top-neg-mob-none">
              <h1 className="header--medium margin-btm-xxs col-7 col-100">Mariana Tek - Admin / Developer Portal</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>During my time at Mariana, I both directed design on the Admin platform, did design work myself and built a full design system (examples pictured above). Not only that, but I also worked with a small team to build out a Developer Portal, where external developers could build features and add them into the Mariana Tek platform.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Director of Design</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Design / UX</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Prototyping</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span className="span-width-sm">Some User Testing</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-5 row-padding flex flex-center col-100 mobile-margin-btm-xs">
              <h3 className="header--small">Admin Example Project</h3>
              <p>Admin is a very complex product, allowing fitness studios to do everything to manage their businesses. Studios can, for example, add and/or manage memberships or credits, have their staff clock in and out, check clients into classes, manage duplicate accounts, etc… I worked on many features and improvements in this system. This example below is one of the many.</p>

              <p>In the Mariana Admin system, adding products a was very complex process. Pieces of functionality appeared when least expected, and adding a product was a very labor intensive and time consuming task. I greatly simplified this process by adding things like bulk actions. I assessed, advised and worked with the team on what we could and should build as an MVP and also provided future additions for sprints down the road.</p>
            </div>
            <div className="column column2 col-7 col-100">
              <div className="project">
                <img src={adminProjects} alt="Mariana Tek 2 screens from admin platform" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth process-bg">
        <div className="section-internal">
          <div className="align-center margin-btm-md">
            <h3 className="header--small">Process</h3>
            <p className="col-8 col-100 img-center text-light">Processes changed based on the feature or functionality we decided to build. What is not included in the below example is the coordination and presentation of findings, designs, etc… to other team members, and exec staff.</p>
          </div>
          <div className="flex-grid-5">
            <div>
              <h4 className="header--smallest">Discovery</h4>
              <p>Gathering information on what we know, have heard, etc… sometimes doing user interviews</p>
              <span className="text-light">Majority of the time my User Researcher did this work</span>
            </div>
            <div>
              <h4 className="header--smallest">Occasional Functionality Analysis / Discussing MVP</h4>
              <p>Next, I found patterns for the pieces of functionality that users would be familiar with and work best for the feature. This allowed us to have potential MVP discussions early on.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">UI / Design / Prototype</h4>
              <p>Based on everything I had seen and heard, I used the UI kit that I created to build out comps as well as a prototype for testing purposes.</p>
              <span className="text-light">Myself</span>
            </div>
            <div>
              <h4 className="header--smallest">Qualitative Testing</h4>
              <p>We often tested the designs to validate them. Testing smaller pieces together and larger pieces of functionality alone.</p>
              <span className="text-light">Majority of the time my User Researcher did this work</span>
            </div>
            <div>
              <h4 className="header--smallest">Coordination with Dev Team - Building Components</h4>
              <p>I always insured the developers were involved from the beginning but once they started building, I worked even closer with them to insure what was built met user expectations. </p>
              <span className="text-light">Myself</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects margin-btm-xl flex-break-sm">
            <div className="column column1 col-8 row-padding col-100 mobile-margin-btm-sm row-padding-md-mob-none mobile-margin-btm-lg">
              <div className="flex flex-grid">
                <div className="project">
                  <img className="withShadow project-with-border" src={embeddedAccount} alt="Developer Portal create account page" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={embeddedApp}  alt="Developer Portal create apps page" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={embeddedInitial} alt="Developer Portal initial screen for apps" />
                </div>
                <div className="project">
                  <img className="withShadow project-with-border" src={embeddedStarted} alt="Developer Portal getting started page" />
                </div>
              </div>
            </div>
            <div className="column column2 col-4 flex flex-center col-100">
              <h3 className="header--small">Developer Portal</h3>
              <p>With a small team, I built out a system for external developers to contribute to Mariana Tek’s platforms. The goal was for external developers or companies to be able to contribute features to Mariana's system that are either outside of Mariana's core features or was something that the external company specialized in (e.g. Vimeo adding funtionality into Mariana's system, instead of Mariana's staff building a video platform). </p>

              <p>This system allowed developers to make an account, get access to the information they needed, e.g. sandboxes, build apps and request permission to push their app(s) to individual studios or eventually, an app store.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="margin-btm-sm">
            <h3 className="header--small">Redesigned Pages</h3>
            <p>Finally, I redesigned many pages in the Mariana Admin system and provided ideas for what external developers could build in Mariana's system, allowing for easier partnerships.</p>
          </div>
          <div className="row-projects flex-break-sm">
            <div className="column column1 row-padding row-padding-md-mob-none">
              <div className="project">
                <img className="withShadow project-with-border" src={redesigned1} alt="Roster printout with more information" />
              </div>
            </div>
            <div className="column column2 row-padding row-padding-md-mob-none">
              <div className="project">
                <img className="withShadow project-with-border" src={redesigned2} alt="New roster page" />
              </div>
            </div>
            <div className="column column2">
              <div className="project">
                <img className="withShadow project-with-border" src={redesigned3} alt="New daily schedule page" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-SY">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link to="/service-year/">
                  <h3 className="header--medium">NCOC - Service Year</h3>
                </Link>
                <span className="description">Design, UX, Prototyping, Testing & more</span>
              </div>
              <Link to="/service-year/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
